<template>
  <MemberSettingContainer>
    <!-- Seetings form -->
    <div v-if="isLoading" class="my-3">
      <b-spinner class="text-primary d-block m-auto" />
    </div>

    <GlobalSettings @isLoading="(loading) => isLoading = loading" />

    <b-card v-if="!isLoading && !currentCollective.hasSSO">
      <validation-observer v-if="!send" #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="d-flex flex-wrap justify-content-between my-2">
            <h4>{{ $t('newPassword.title') }}</h4>
            <b-button variant="primary" type="submit">
              {{ $t('form.actions.save') }}
            </b-button>
          </div>
          <b-row>
            <b-col lg="6">
              <!-- Current password -->
              <validation-provider #default="validationContext" name=" " rules="required">
                <b-form-group>
                  <label for="actualPassw" class="font-weight-bold">
                    {{ $t('newPassword.form.password.label') }}*</label>
                  <b-form-input
                    id="actualPassw"
                    v-model="form.password"
                    type="password"
                    :placeholder="$t('form.type-placeholder')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback class="text-danger">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="6">
              <b-form>
                <label for="s" class="font-weight-bold">
                  {{ $t('newPassword.knowledge') }}
                </label>
              </b-form>
              <b-button :href="helpLocation" target="_blank" variant="outline-primary">
                {{ $t('backoffice.dashboard.help-center') }}
              </b-button>
            </b-col>
          </b-row>
          <!-- New password -->
          <b-row>
            <b-col cols="6">
              <validation-provider #default="validationContext" name=" " rules="required|max:255">
                <b-form-group>
                  <label for="newPassw" class="font-weight-bold"> {{ $t('newPassword.form.password2.label') }}*</label>
                  <b-form-input
                    id="newPassw"
                    v-model="form.newPassword"
                    type="password"
                    :placeholder="$t('form.type-placeholder')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback class="text-danger">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- Repeat New password -->
              <validation-provider #default="validationContext" name=" " rules="required|max:255">
                <b-form-group>
                  <label for="newPassw2" class="font-weight-bold"> {{ $t('newPassword.form.password3.label') }}*</label>
                  <b-form-input
                    id="newPassw2"
                    v-model="form.newPassword2"
                    type="password"
                    :placeholder="$t('form.type-placeholder')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback class="text-danger">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <div v-else>
        <b-alert variant="success" show>
          {{ $t('newPassword.form.password-success') }}
        </b-alert>
      </div>
    </b-card>
    <!-- Danger Zone -->
    <DangerZone v-if="!isLoading" :userkey="loggedMember.key" />
  </MemberSettingContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';
// import GlobalSettings from '@/views/myself/components/GlobalSettings.vue';
// import DangerZone from '@/views/myself/components/DangerZone.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    MemberSettingContainer,
    GlobalSettings: () => import('@/views/myself/components/GlobalSettings.vue' /* webpackChunkName: "GlobalSettings" */),
    DangerZone: () => import('@/views/myself/components/DangerZone.vue' /* webpackChunkName: "DangerZone" */),
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        password: '',
        newPassword: '',
        newPassword2: '',
      },
      required,
      max,
      isStaff: false,
      send: false,
      isLoading: false,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    ...mapGetters(['loggedMember', 'currentCollective']),
    
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    helpLocation() {
      return process.env.VUE_APP_HELP_CENTER;
    },
  },
  async created() {
    this.isStaff = this.currentCollective.isStaff;
  },
  methods: {
    async onSubmit() {
      if (this.form.newPassword === this.form.newPassword2) {
        try {
          const response = await this.$store.dispatch('changePassword', {
            requestConfig: {
              oldPassword: this.form.password,
              password: this.form.newPassword,
              repeatPassword: this.form.newPassword2,
            },
          });
          if (response.data.ok) {
            this.notifySuccess(this.$t('backoffice.settings.messages.success'));
            this.send = true;
          }
          return response;
        } catch (error) {
          this.send = false;
          this.notifyError(this.$t('newPassword.form.same-old-password'));
        }
      } else {
        this.notifyError(this.$t('newPassword.form.not-same-password'));
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
</style>
